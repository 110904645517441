import Logo from './UNSOLO_black.png'
import wp from './whatsapp.png'
import ig from './instagram.png'


const App = () => {
  return (
   <section>

    <div className='img_container'> 
      <img src={Logo} alt='Bacground Photo by Rachel Claire: https://www.pexels.com/photo/group-of-friends-watching-the-sunset-6125822/' />
    </div>
    <div class="container">
       <div>
        <h1>Launching Soon</h1>
        <p>Embrace the Excitement, <br /> Our Journey Begins Soon!</p>
        <div className='btn_container'>
         <a target='_blank' href="https://chat.whatsapp.com/BVGArvdT7uA952qk5saKFa"><button className='button1'><span>Join our Community</span> <img src={wp} alt='' /></button></a>
         <a target='_blank' href="https://www.instagram.com/unsolo.in/"><button className='button2'><span>Follow us on Instagram </span><img src={ig} alt='' /></button></a>
        </div>
      </div>
    </div>
    
    </section>
  )
}

export default App